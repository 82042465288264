import React from "react"
import { Layout, Parallax } from "@components/layout"
import {
  Breadcrumbs,
  AnimatedLink,
} from "@components/shared"

import {
  page__wrapper,
  page__content,
  page__content__btn,
} from "./styles/other.module.scss"

const Other = ({ pageContext }) => {
  const { page } = pageContext
  return (
    <Layout
      animation={false}
      seo={pageContext.page.seo}
      siteMetaDaa={pageContext.page.siteMetaData}
    >
      <Parallax height={300} title={"Inne"}>
      </Parallax>
      <div className={page__wrapper}>
        <div className={page__content}>
          <Breadcrumbs
            elements={[
              {
                label: page.title,
                url: `/${page.slug}/`,
              },
            ]}
          />
          <div className="row">
            <AnimatedLink className={page__content__btn} to={"/answers"}>
              Ankiety
            </AnimatedLink>
            <h5>Ankiety na stronie</h5>
          </div>

          <div className="row">
            <AnimatedLink className={page__content__btn} to={"/tags"}>
              Tagi
            </AnimatedLink>
            <h5>Tagi na stronie</h5>
          </div>

          <div className="row">
            <AnimatedLink className={page__content__btn} to={"/regulaminy"}>
              Regulaminy
            </AnimatedLink>
            <h5>Regulaminy gladiators-of-javascript</h5>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Other
